import { useStaticQuery, graphql } from 'gatsby'

interface ISiteMetadata {
  title: string
  description: string
  keywords: string
  contact: {
    facebook: string
    instagram: string
    hashtag: string
    email: string
    tel: string
    address: string
  }
}

const useSiteMetadata = (): ISiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            keywords
            contact {
              facebook
              instagram
              hashtag
              email
              tel
              address
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
