import React from 'react'
import Helmet from 'react-helmet'

import useSiteMetadata from '../hooks/build/useSiteMetadata'

interface SeoProps {
  title?: string
  description?: string
  keywords?: string
}

const Seo: React.FC<SeoProps> = ({ title, description, keywords }) => {
  const data = useSiteMetadata()

  return (
    <Helmet>
      <title>{title || data.title}</title>
      <meta name="description" content={description || data.description} />
      <meta name="keywords" content={keywords || data.keywords} />
      <meta property="og:title" content={title || data.title} />
      <meta property="og:description" content={description || data.description} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default Seo
