/* tslint:disable:max-line-length */

export const COLORS = {
  brand: '#099788',
  accent: '#3c3c3b',
  success: '#37b635',
  warning: '#ec1818',
  gray: {
    dark: 'hsla(270, 17.119554496%, 0%, 0.92)',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    calm: 'rgba(0, 0, 0, 0.54)'
  },
  white: '#fff',
  black: '#000',
  ice: '#e2ecea',
  greenishBlue: '#099788',
  brownGrey: '#939393',
  lightRed: '#fa494a'
}

export const FONTS = {
  brand: 'SpaceGrotesk, Arial',
  sansSerif: 'DM Sans, sans-serif'
}

export const TRANSITIONS = {
  base: 'all 0.3s ease-in-out'
}

// Used for title - deprecated
export const FONT_SIZES = {
  title: {
    sm: {
      xl: '5rem',
      l: '2.235rem'
    },
    xl: '2.5rem',
    l: '1.5rem'
  }
}

export const BREACKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

// export const CONTAINER_PADDING = {
//   xs: '25px',
//   sm: '25px',
//   md: '16px',
//   lg: '16px',
//   xl: '16px'
// }

// export const WIDTHS = {
//   md: 720,
//   lg: 960,
//   xl: 1140
// }

// export const SPACING = {
//   xxs: '.25rem',
//   xs: '.5rem',
//   sm: '1rem',
//   md: '1.5rem',
//   lg: '2rem',
//   xl: '3rem',
//   xxl: '4rem'
// }

// Used for reset
export const DIMENSIONS = {
  fontSize: {
    regular: 12,
    large: 16
  },
  headingSizes: {
    h1: 1.7,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPadding: 1.5,
  radius: {
    small: 0.25,
    medium: 0.5,
    large: 0.75
  }
}
