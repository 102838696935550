/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import 'normalize.css'

import Seo from '../containers/Seo'
import GlobalStyle from './../assets/styles/normalize'

/**
 * 
 font-family: 'Helvetica Neue', sans-serif;
font-family: 'Helvetica 25 UltraLight', sans-serif;
font-family: 'Helvetica 35 Thin', sans-serif;
font-family: 'Helvetica 45 Light', sans-serif;
font-family: 'Helvetica 55 Roman', sans-serif;
font-family: 'Helvetica 65 Medium', sans-serif;
 */
import imageDesktop from '../assets/images/background-home.jpg'
import imageMobile from '../assets/images/background-homemobile.jpg'

const Page = styled.div`
  background-image: url(${imageDesktop});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 879px) {
    background-position: bottom;
    background-image: url(${imageMobile});
  }
`

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  padding: 24px;
  margin: auto;
  display: flex;
  align-items: center;
  @media (max-width: 879px) {
    align-items: flex-start;
    margin-top: 5vh;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;

  @media (max-width: 879px) {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`
const Title = styled.h1`
  color: #fefefe;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  margin: 0;
`

const Small = styled.span`
  font-size: 1.2rem;
  margin-bottom: 3px;
  margin-left: 15px;

  @media (max-width: 879px) {
    font-size: 1rem;
  }
`
//   font-size: 4rem;
const Big = styled.span`
  font-size: 6rem;
  font-style: italic;
  font-family: 'Helvetica Neue Medium';
  margin-left: -5px;

  @media (max-width: 879px) {
    font-size: 4.5rem;
  }
`

const Asterix = styled.p`
  font-size: 0.8rem;
  margin-top: 8px;
  margin-left: 10px;
`

const Link = styled.a`
  padding: 15px 25px 10px;
  border-radius: 10px;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  background-color: #fefefe;
  font-family: 'Helvetica Neue Bold';
  color: #000000;
  align-self: flex-start;
  cursor: pointer;
  margin-top: -15px;

  @media (max-width: 879px) {
    line-height: 0.8rem;
    font-size: 0.8rem;
    align-self: center;
  }

  &:hover {
    background-color: #ffeedc;
    text-decoration: none;
  }
`

const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <html lang="fr" />
      </Helmet>
      <Seo />
      <Page>
        <Container>
          <TextWrapper>
            <Title>
              <Small>Journey devient*</Small>
              <Big>Dynamo</Big>
            </Title>
            <Link href="https://dynamo-bordeaux.com/" target="_self">
              Découvrir
            </Link>
            <Asterix>* vos identifiants restent inchangés</Asterix>
          </TextWrapper>
        </Container>
      </Page>
    </>
  )
}

export default IndexPage
